ACC.addressDoctor = {

    _autoload: [
        ['showSuggestedAddressesPopup', $('#popup_suggested_delivery_addresses_form').length !== 0],
        'bindAll',
    ],

    defaultPostalCode: '0000',

    bindAll() {
        if (ACC.config.addressDoctorEnabled) {
            ACC.addressDoctor.bindSelectBillingAddressSuggestion();
            ACC.addressDoctor.bindUseThisAddress();
            ACC.addressDoctor.bindReenterAddressButton();
            ACC.addressDoctor.bindEditAddress();
            ACC.addressDoctor.submitAndDisableButton();
        }
    },

    checkShowingSuggestedAddressesPopup(form, url, isQuoteCheckout) {
        const vertexInvalidAddress = $('#isVertexInvalidAddress').val() === 'true';
        if (ACC.config.billingAddressUrl === null || ACC.config.billingAddressUrl === '' || vertexInvalidAddress) {
            return;
        }
        const isExistingAddress = ACC.secureacceptance.isSavedAddress() || ACC.secureacceptance.useDeliveryAddress();
        $.ajax({
            type: 'POST',
            url: ACC.config.encodedContextPath + url,
            data: form,
            async: true,
            success(data) {
                const billingAddressHasNoFieldErrors = ACC.addressDoctor.isBillingAddressWithoutFieldErrors();
                if (data.showSuggestedAddressesPopup && billingAddressHasNoFieldErrors) {
                    ACC.addressDoctor.showSuggestedAddressesPopup();
                } else if (!data.isAddressInvalid && billingAddressHasNoFieldErrors) {
                    if (isQuoteCheckout) {
                        ACC.quote.updateAddress(form);
                    } else {
                        ACC.addressDoctor.updateAddress(form);
                    }
                } else if (isExistingAddress) {
                    $('#addressValidationError').removeClass('hidden');
                    $('#addressValidationErrorMsg').html(data.addressErrorMessage);
                }
            },
            error(xhr, status, error) {
                console.error(error.message);
                // eslint-disable-next-line no-undef
                if (reloadOnError) {
                    window.location.reload();
                }
            },
        });
    },

    showSuggestedAddressesPopup() {
        if (ACC.config.isHidePostalCodeFieldByCountryListEnabled) {
            if (document.getElementById('address.country')) {
                const countryIsoCode = document.getElementById('address.country').value;
                ACC.address.hidePostalZipCode(countryIsoCode);
            } else if (document.getElementById('defaultAddressCountrySelector')) {
                const countryIsoCode = document.getElementById('defaultAddressCountrySelector').value;
                ACC.address.hidePostalZipCode(countryIsoCode);
                // eslint-disable-next-line max-len
            } else if (document.getElementById('registerNewCompanyMiniForm.orgAddressCountrySelector')) {
                // eslint-disable-next-line max-len
                const countryIsoCode = document.getElementById('registerNewCompanyMiniForm.orgAddressCountrySelector').value;
                ACC.address.hidePostalZipCode(countryIsoCode);
            }
        }
        const modalId = '#popup_suggested_delivery_addresses_form';
        const modalTitle = $(modalId).data('modal-title');
        $modal.open(null, {
            inline: true,
            href: modalId,
            title: modalTitle,
        });
        const modal = document.getElementById('colorbox');
        if (modal.querySelector('#popup_suggested_delivery_addresses_form')) {
            const suggestedAddressesForm = document.getElementById('popup_suggested_delivery_addresses_form');
            const bodyMobile = document.querySelector('.iti-mobile');
            const checkIsMobile = !!bodyMobile;
            const checkoutButtonText = ACC.addressDoctor.isQuoteCreate() ? 'Generate Quote' : 'Place order';

            if (suggestedAddressesForm) {
                // eslint-disable-next-line max-len
                suggestedAddressesForm.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('suggestedAddresses__modal');
                suggestedAddressesForm.classList.add('isVisible');
                if (checkIsMobile) {
                    bodyMobile.classList.add('isMobile');
                }

                document.getElementById('cboxOverlay').addEventListener('click', () => {
                    ACC.buttonhelper.disable('.place-order-form #placeOrder', checkoutButtonText);
                    ACC.buttonhelper.enable('.js-address-doctor-use-this-address');
                    if (checkIsMobile) {
                        bodyMobile.classList.remove('isMobile');
                    }
                    suggestedAddressesForm.classList.remove('isVisible');
                    modal.classList.remove('suggestedAddresses__modal');
                });

                document.getElementById('cboxClose').addEventListener('click', () => {
                    ACC.buttonhelper.disable('.place-order-form #placeOrder', checkoutButtonText);
                    ACC.buttonhelper.enable('.js-address-doctor-use-this-address');
                    if (checkIsMobile) {
                        bodyMobile.classList.remove('isMobile');
                    }
                    if (window.location.href.indexOf('select-address') !== -1) {
                        window.location = 'add';
                    }
                    suggestedAddressesForm.classList.remove('isVisible');
                    modal.classList.remove('suggestedAddresses__modal');
                });
            }
        }
    },

    updateAddress(form) {
        const sopPaymentDetailsForm = $('#sopPaymentDetailsForm');
        $.ajax({
            type: 'POST',
            url: `${ACC.config.encodedContextPath}/checkout/multi/select/suggestion/billingAddress`,
            data: form,
            dataType: 'html',
            async: true,
            success(data) {
                ACC.secureacceptance.updateBillingAddressForm(data);
                ACC.addressDoctor.updateAddressInfo(sopPaymentDetailsForm, false);
                ACC.validation.triggerManualValidation('#placeOrderForm1');
            },
            error(xhr, status, error) {
                console.log(xhr.responseText);
                if (error) {
                    console.error(error.message);
                }
            },
        });
    },

    bindSelectBillingAddressSuggestion() {
        $(document).on('submit', '#sopPaymentDetailsForm', function (event) {
            event.preventDefault();
            const form = $(this);
            // eslint-disable-next-line max-len
            const areSuggestionsAvailable = $('.js-address-doctor__billing-address-suggestions').data('are-suggestions-available');
            const data = areSuggestionsAvailable ? ACC.addressDoctor.collectSuggestedBillingAddressForm(form)
                : ACC.secureacceptance.collectBillingAddressForm();

            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/multi/select/suggestion/billingAddress`,
                data,
                dataType: 'html',
                // eslint-disable-next-line no-shadow
                success(data) {
                    ACC.secureacceptance.updateBillingAddressForm(data);
                    ACC.addressDoctor.updateAddressInfo(form, true);
                    ACC.validation.triggerManualValidation('#placeOrderForm1');
                    $modal.close();
                },
                error(xhr, status, error) {
                    if (error) {
                        console.error(error.message);
                    }

                    // eslint-disable-next-line no-undef
                    if (reloadOnError) {
                        window.location.reload();
                    }
                },
            });
        });

        $(document).on('submit', '#quoteBillingAddressForm', function (event) {
            event.preventDefault();

            const form = $(this);
            const formData = form.serialize();

            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/checkout/quote/create/multi/select/suggestion/billingAddress`,
                data: formData,
                success(data) {
                    billingAddressFormDataResponse = data;
                },
                error(xhr) {
                    console.log(xhr.responseText);
                    // eslint-disable-next-line no-undef
                    ACC.buttonhelper.enable(clickedEl);
                },
            }).done(() => {
                $('#quoteBillingAddressFormContainer').html(billingAddressFormDataResponse);
                // eslint-disable-next-line max-len
                $('#searchAddressForm input[name="addressId"]').val($('#quoteBillingAddressFormContainer input[id="ADSuggestionAddressId"]').val());
                $('#useQuoteDeliveryAddress').prop('checked', false);
                ACC.utils.preselectValue('#address\\.country');
                ACC.addressDoctor.updateQuoteAddressInfo(ACC.addressDoctor.convertFormData(form));

                $modal.close();
                document.getElementById('colorbox').classList.remove('suggestedAddresses__modal');
                ACC.buttonhelper.enable('.js-address-doctor-use-this-address');

                if ($('#isCheckoutV2PersonalContextAndDigitalOnly').val() === 'true') {
                    ACC.secureacceptance.reloadOrderTotals();
                }
            });
        });
    },

    convertFormData(form) {
        const formdata = form.serializeArray();
        const data = {};
        $(formdata).each((index, obj) => {
            data[obj.name] = obj.value;
        });

        return data;
    },

    bindReenterAddressButton() {
        const bodyMobile = $('.iti-mobile');
        const checkIsMobile = !!bodyMobile;
        const suggestedAddressesForm = $('#popup_suggested_delivery_addresses_form');
        const modal = $('#colorbox');
        const checkoutButtonText = ACC.addressDoctor.isQuoteCreate() ? 'Generate Quote' : 'Place order';

        $(document).on('click', '#reenter_address_button', () => {
            ACC.buttonhelper.disable('.place-order-form #placeOrder', checkoutButtonText);
            ACC.buttonhelper.enable('.js-address-doctor-use-this-address');
            $modal.close();
            if (window.location.href.indexOf('select-address') !== -1) {
                window.location = 'add';
            }
            if (checkIsMobile && bodyMobile.length) {
                bodyMobile.classList.remove('isMobile');
            }
            if (suggestedAddressesForm.classList) {
                suggestedAddressesForm.classList.remove('isVisible');
            }
            if (modal.classList) {
                modal.classList.remove('suggestedAddresses__modal');
            }
            ACC.silentorderpost.bindCreditCardAddressForm();
        });
    },

    isQuoteCreate() {
        return window.location.href.indexOf('/checkout/quote/create') >= 0;
    },

    bindUseThisAddress() {
        const isQuoteCheckout = ACC.addressDoctor.isQuoteCreate();
        const addressDoctorUseThisAddressSelector = '.js-address-doctor-use-this-address';
        const billingAddressFormSelector = isQuoteCheckout ? '#quoteBillingAddressForm' : '#billingAddressForm';
        // eslint-disable-next-line max-len
        const billingAddressContainer = isQuoteCheckout ? $('#quoteBillingAddressFormContainer') : $('#billingAddressSection');

        billingAddressContainer.on('change paste keyup blur', `${billingAddressFormSelector} input, select`, () => {
            // eslint-disable-next-line no-unused-expressions
            ACC.validation.validateFormDivOnlyWhenMandatoryFieldsArePopulated(billingAddressFormSelector)
                ? ACC.buttonhelper.enable(addressDoctorUseThisAddressSelector)
                : ACC.buttonhelper.disable(addressDoctorUseThisAddressSelector);
        });

        $(document).on('click', addressDoctorUseThisAddressSelector, () => {
            ACC.buttonhelper.disable(addressDoctorUseThisAddressSelector).withSpinner();
            if (isQuoteCheckout) {
                ACC.quote.validateBillingAddress(null, true);
            } else {
                ACC.secureacceptance.updateBillingAddress(
                    () => ACC.addressDoctor.checkShowingSuggestedAddressesPopup(
                        ACC.secureacceptance.collectBillingAddressForm(),
                        '/checkout/multi/validate/showSuggestedAddressesPopup',
                        false,
                    ),
                    ACC.secureacceptance.updateBillingAddressForm,
                    true,
                );
            }
        });
    },

    bindEditAddress() {
        const addressDoctorUseThisAddressSelector = '.js-address-doctor-use-this-address';

        $(document).on('click', '.js-address-doctor-edit-address', () => {
            const editAddressButton = $('.js-address-doctor-edit-address');
            const billingAddressFormCountrySelector = $('#billingCountrySelector');
            // eslint-disable-next-line max-len
            const billingAddressForm = ACC.addressDoctor.isQuoteCreate() ? $('#quoteBillingAddressForm') : $('#billingAddressForm');
            const countryIsoCode = document.getElementById('address.country').value;
            ACC.address.hidePostalZipCode(countryIsoCode);
            billingAddressForm.show();
            billingAddressFormCountrySelector.show();
            ACC.silentorderpost.bindCreditCardAddressForm();
            editAddressButton.hide();

            ACC.addressDoctor.clearAddressInfo(billingAddressForm);
            ACC.buttonhelper.enable(addressDoctorUseThisAddressSelector);
            ACC.validation.triggerManualValidation('#placeOrderForm1');
        });
    },

    submitAndDisableButton() {
        $('.use_address').on('click', function () {
            this.disabled = true;
            this.form.submit();
        });
    },

    updateAddressInfo(component, dataFromADpopup) {
        // eslint-disable-next-line no-unused-expressions
        ACC.addressDoctor.isNewBillingAddress() ? ACC.addressDoctor.updateNewAddressInfo(component, dataFromADpopup)
            : ACC.addressDoctor.updateSearchedAddressInfo();
    },

    updateQuoteAddressInfo(addressData) {
        const editAddressButton = $('.js-address-doctor-edit-address');
        const quoteBillingAddressForm = $('#quoteBillingAddressForm');
        const searchSavedAddress = $('.address-search .help-block');
        const isSearchButtonChecked = $("input:radio[id='searchAddress']").is(':checked');

        if (isSearchButtonChecked) {
            ACC.addressDoctor.appendQuoteAddressInfo(searchSavedAddress, addressData);
        } else {
            quoteBillingAddressForm.hide();
            editAddressButton.show();
            ACC.addressDoctor.appendQuoteAddressInfo(quoteBillingAddressForm, addressData);
        }
        ACC.validation.triggerManualValidation('#placeOrderForm1');
    },

    updateSearchedAddressInfo() {
        const searchAddress = $('.address-search .help-block');
        const billingAddressForm = ACC.secureacceptance.collectBillingAddressForm();

        ACC.addressDoctor.appendAddressInfo(searchAddress, billingAddressForm);
    },

    updateNewAddressInfo(component, dataFromADpopup) {
        // eslint-disable-next-line max-len
        const areSuggestionsAvailable = $('.js-address-doctor__billing-address-suggestions').data('are-suggestions-available');
        // eslint-disable-next-line max-len
        const formData = areSuggestionsAvailable && dataFromADpopup ? ACC.addressDoctor.collectSuggestedBillingAddressForm(component)
            : ACC.secureacceptance.collectBillingAddressForm();
        const billingAddressFormCountrySelector = $('#billingCountrySelector');
        const billingAddressForm = $('#billingAddressForm');
        const editAddressButton = $('.js-address-doctor-edit-address');

        billingAddressFormCountrySelector.hide();
        billingAddressForm.hide();
        editAddressButton.show();

        ACC.addressDoctor.appendAddressInfo(billingAddressForm, formData);
    },

    appendAddressInfo(container, data) {
        const title = ACC.addressDoctor.getTitleName();
        const firstName = data.billTo_firstName ? `${data.billTo_firstName} ` : '';
        const lastName = data.billTo_lastName ? data.billTo_lastName : '';
        const street1 = data.billTo_street1 ? `${data.billTo_street1} ` : '';
        const street2 = data.billTo_street2 ? `${data.billTo_street2} ` : '';
        const street3 = data.billTo_street3 ? `${data.billTo_street3} ` : '';
        const city = data.billTo_city ? ACC.addressDoctor.getTownCity(data.billTo_city) : '';
        const state = data.billTo_state ? ACC.addressDoctor.getRegionName(data.billTo_state) : '';
        const postalCode = data.billTo_postalCode ? data.billTo_postalCode : '';
        const country = ACC.addressDoctor.getCountryName(data.billTo_country);
        const email = data.billTo_email ? `${data.billTo_email}<br>` : '';
        const phoneNumber = data.billTo_phoneNumber ? data.billTo_phoneNumber : '';

        const firstNameReplaced = ACC.replaceSpecialCharacters.replaceCharacters(firstName);
        const lastNameReplaced = ACC.replaceSpecialCharacters.replaceCharacters(lastName);
        const street1Replaced = ACC.replaceSpecialCharacters.replaceCharacters(street1);
        const street2Replaced = ACC.replaceSpecialCharacters.replaceCharacters(street2);
        const street3Replaced = ACC.replaceSpecialCharacters.replaceCharacters(street3);
        const cityReplaced = ACC.replaceSpecialCharacters.replaceCharacters(city);

        ACC.addressDoctor.clearAddressInfo(container);

        if (postalCode === ACC.addressDoctor.defaultPostalCode) {
            // eslint-disable-next-line max-len
            ACC.addressDoctor.getAddressLineWithoutPostalCode(container, title, firstNameReplaced, lastNameReplaced, street1Replaced, street2Replaced, street3Replaced, cityReplaced, state, country, email, phoneNumber);
        } else {
            // eslint-disable-next-line max-len
            ACC.addressDoctor.getAddressLineWithPostalCode(container, title, firstNameReplaced, lastNameReplaced, street1Replaced, street2Replaced, street3Replaced, cityReplaced, state, postalCode, country, email, phoneNumber);
        }
    },

    appendQuoteAddressInfo(container, data) {
        const firstName = data.firstName ? `${data.firstName} ` : '';
        const lastName = data.lastName ? data.lastName : '';
        const street1 = data.line1 ? `${data.line1} ` : '';
        const street2 = data.line2 ? `${data.line2} ` : '';
        const street3 = data.line3 ? `${data.line3} ` : '';
        const city = data.townCity ? ACC.addressDoctor.getTownCity(data.townCity) : '';
        const state = data.regionIso ? ACC.addressDoctor.getRegionName(data.regionIso) : '';
        const postalCode = data.postcode ? data.postcode : '';
        const country = ACC.addressDoctor.getCountryName(data.countryIso);
        const phoneNumber = data.phone ? data.phone : '';

        const firstNameReplaced = ACC.replaceSpecialCharacters.replaceCharacters(firstName);
        const lastNameReplaced = ACC.replaceSpecialCharacters.replaceCharacters(lastName);
        const street1Replaced = ACC.replaceSpecialCharacters.replaceCharacters(street1);
        const street2Replaced = ACC.replaceSpecialCharacters.replaceCharacters(street2);
        const street3Replaced = ACC.replaceSpecialCharacters.replaceCharacters(street3);
        const cityReplaced = ACC.replaceSpecialCharacters.replaceCharacters(city);

        ACC.addressDoctor.clearAddressInfo(container);

        if (postalCode === ACC.addressDoctor.defaultPostalCode) {
            // eslint-disable-next-line max-len
            ACC.addressDoctor.getQuoteAddressLineWithoutPostalCode(container, firstNameReplaced, lastNameReplaced, street1Replaced, street2Replaced, street3Replaced, cityReplaced, state, country, phoneNumber);
        } else {
            // eslint-disable-next-line max-len
            ACC.addressDoctor.getQuoteAddressLineWithPostalCode(container, firstNameReplaced, lastNameReplaced, street1Replaced, street2Replaced, street3Replaced, cityReplaced, state, postalCode, country, phoneNumber);
        }
    },

    // eslint-disable-next-line max-len
    getAddressLineWithoutPostalCode(container, title, firstName, lastName, street1, street2, street3, city, state, country, email, phoneNumber) {
        if (!ACC.config.isAssessmentsCheckoutV2Enabled) {
            container.after(
                // eslint-disable-next-line no-undef
                DOMPurify.sanitize(`<ul class="address">${title}${firstName}${lastName}<br>
                    ${street1}${street2}${street3}<br>${city}${state}<br>
                    ${country}${email}${phoneNumber}
                </ul>`),
            );
        } else {
            street1 = street1.trimEnd().concat(', ');
            // eslint-disable-next-line no-lonely-if
            if (ACC.config.isAddressLine3MultiLineEnabled && street3) {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}<br>${street3}<br>
                    ${city}, ${state}<br>
                    ${country}
                </ul>`),
                );
            } else {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}${street3}<br>
                    ${city}, ${state}<br>
                    ${country}
                  </ul>`),
                );
            }
        }
    },

    // eslint-disable-next-line max-len
    getAddressLineWithPostalCode(container, title, firstName, lastName, street1, street2, street3, city, state, postalCode, country, email, phoneNumber) {
        if (!ACC.config.isAssessmentsCheckoutV2Enabled) {
            container.after(
                // eslint-disable-next-line no-undef
                DOMPurify.sanitize(`<ul class="address">${title}${firstName}${lastName}<br>
                    ${street1}${street2}${street3}<br>${city}${state}${postalCode}<br>
                    ${country}${email}${phoneNumber}
                </ul>`),
            );
        } else {
            state = state ? `${state}, ` : '';
            street1 = street1.trimEnd().concat(', ');
            if (ACC.config.isAddressLine3MultiLineEnabled && street3) {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}<br>${street3}<br>
                        ${city}, ${state}${postalCode}<br>
                        ${country}
                    </ul>`),
                );
            } else {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}${street3}<br>
                        ${city}, ${state}${postalCode}<br>
                        ${country}
                    </ul>`),
                );
            }
        }
    },

    // eslint-disable-next-line max-len
    getQuoteAddressLineWithoutPostalCode(container, firstName, lastName, street1, street2, street3, city, state, country, phoneNumber) {
        if (!ACC.config.isAssessmentsCheckoutV2Enabled) {
            container.after(
                // eslint-disable-next-line no-undef
                DOMPurify.sanitize(`<ul class="address">${
                    firstName}${lastName}<br>${
                    street1}${street2}${street3}<br>${city}${state}<br>${
                    country}${phoneNumber
                }</ul>`),
            );
        } else {
            street1 = street1.trimEnd().concat(', ');
            // eslint-disable-next-line no-lonely-if
            if (ACC.config.isAddressLine3MultiLineEnabled && street3) {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}<br>${street3}<br>
                        ${city}, ${state}<br>
                        ${country}
                    </ul>`),
                );
            } else {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}${street3}<br>
                        ${city}, ${state}<br>
                        ${country}
                    </ul>`),
                );
            }
        }
    },

    // eslint-disable-next-line max-len
    getQuoteAddressLineWithPostalCode(container, firstName, lastName, street1, street2, street3, city, state, postalCode, country, phoneNumber) {
        if (!ACC.config.isAssessmentsCheckoutV2Enabled) {
            container.after(
                // eslint-disable-next-line no-undef
                DOMPurify.sanitize(`<ul class="address">${
                    firstName}${lastName}<br>${
                    street1}${street2}${street3}<br>${city}${state}${postalCode}<br>${
                    country}${phoneNumber
                }</ul>`),
            );
        } else {
            state = state ? `${state}, ` : '';
            street1 = street1.trimEnd().concat(', ');
            if (ACC.config.isAddressLine3MultiLineEnabled && street3) {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}<br>${street3}<br>
                        ${city}, ${state}${postalCode}<br>
                        ${country}
                    </ul>`),
                );
            } else {
                container.after(
                // eslint-disable-next-line no-undef
                    DOMPurify.sanitize(`<ul class="address">${street1}${street2}${street3}<br>
                        ${city}, ${state}${postalCode}<br>
                        ${country}
                    </ul>`),
                );
            }
        }
    },

    clearAddressInfo(container) {
        container.parent().find('ul.address').remove();
    },

    getCountryName(countryIso) {
        // eslint-disable-next-line max-len
        const component = ACC.secureacceptance.identifyBillingAddressFormContainer();
        const countryName = component.find($('#address\\.country option:selected')).text();
        // eslint-disable-next-line no-undef
        return countryName ? DOMPurify.sanitize(`${countryName}<br>`) : DOMPurify.sanitize(`${countryIso}<br>`);
    },

    getRegionName(regionIso) {
        // eslint-disable-next-line max-len
        const component = ACC.secureacceptance.identifyBillingAddressFormContainer();
        const regionName = component.find($('#address\\.region option:selected')).text();
        // eslint-disable-next-line no-undef
        return regionName ? DOMPurify.sanitize(`${regionName}`) : DOMPurify.sanitize(`${regionIso}`);
    },

    getTitleName() {
        // eslint-disable-next-line max-len
        const component = $('.payByPORadioBtn').is(':checked') ? $('#learnerSopPaymentDetailsForm') : $('#silentOrderPostForm');
        const titleName = component.find($('#address\\.title option:selected:not(:disabled)')).text();
        return titleName ? `${titleName} ` : '';
    },

    collectSuggestedBillingAddressForm(component) {
        const collectedData = {};

        ACC.addressDoctor.performTrimBeforeSubmit(component);
        collectedData.billTo_country = component.find($('#suggestedAddress\\.country')).val();
        collectedData.billTo_firstName = component.find($('#suggestedAddress\\.firstName')).val();
        collectedData.billTo_lastName = component.find($('#suggestedAddress\\.surname')).val();
        collectedData.billTo_street1 = component.find($('#suggestedAddress\\.line1')).val();
        collectedData.billTo_street2 = component.find($('#suggestedAddress\\.line2')).val();
        collectedData.billTo_street3 = component.find($('#suggestedAddress\\.line3')).val();
        collectedData.billTo_city = component.find($('#suggestedAddress\\.townCity')).val();
        collectedData.billTo_postalCode = component.find($('#suggestedAddress\\.postcode')).val();
        collectedData.billTo_titleCode = component.find($('#suggestedAddress\\.title')).val();
        // eslint-disable-next-line max-len
        collectedData.billTo_phoneNumber = ACC.phonemasking.collectPhoneNumber(component.find($('#suggestedAddress\\.phone')));
        collectedData.billTo_state = component.find($('#suggestedAddress\\.region')).val();
        collectedData.billTo_email = component.find($('#suggestedAddress\\.email')).val();
        collectedData.useDeliveryAddress = $('#useDeliveryAddress').prop('checked');
        collectedData.savePaymentInfo = $('#savePaymentInfo').prop('checked');
        collectedData.saveInAddressBook = $('#saveBillingAddressInMyAddressBook').prop('checked');

        return collectedData;
    },

    isNewBillingAddress() {
        return !$('#billingAddressFormContainer').is(':hidden');
    },

    isBillingAddressWithoutFieldErrors() {
        const addNewAddressContainer = $('.add-new-address');
        if (addNewAddressContainer.length > 0) {
            const errorElements = addNewAddressContainer.find('.has-error');
            const errorVisible = errorElements.is(':visible');
            return !(errorElements.length && errorVisible);
        }
        return true;
    },

    performTrimBeforeSubmit(component) {
        // eslint-disable-next-line no-unused-vars
        const fields = component.find($('input[id^="suggestedAddress"]')).each(function () {
            this.value = this.value.trim();
        });

        return true;
    },

    getTownCity(townCity) {
        return ACC.config.isAssessmentsCheckoutV2Enabled ? `${townCity}` : `${townCity}, `;
    },
};
