ACC.newOrgAddress = {

    _autoload: [
        'bindCheckExistingOrganizations',
        'bindResetForm',
        'bindRegisterNewOrgAnyway',
        'bindRegisterOrg',
        'bindEnterKeyOnSubmitForm',
        'bindRadioButtonSelection',
        'bindAddNewAddressRadioForm',
        'bindExistingOrgRadioButton',
        'bindTermsConditionExistingOrg',
        'showOrgErrorPopup',
        'bindToggleContinueButton',
        ['bindQGlobalSearchForm', $('#registration-q-global-search-form').length > 0],
        ['displayAddressLine3', ACC.config.isRegisterOrganisationVb4cEnabled],
        'bindCountrySelector',
        ['bindQglobalAccountSearchButtons', ACC.config.isRegisterOrganisationVb4cEnabled],
        ['bindPreselectExistingOrg', ACC.config.isRegisterOrganisationVb4cEnabled],
        ['bindShowMoreOrganizationsButton', ACC.config.isRegisterOrganisationVb4cEnabled],
    ],

    ORG_NAME_PLACEHOLDER: '<org_name>',

    bindCheckExistingOrganizations() {
        $('.js-register-new-company-form__check-existing').on('click', function () {
            if (ACC.newOrgAddress.isUsingExistingOrganization()) {
                ACC.newOrgAddress.submitAssociateToExistingForm();
                return;
            }

            if (ACC.newOrgAddress.isOrganizationAlreadySearched()) {
                ACC.newOrgAddress.associateToSuggestedCompany();
                return;
            }

            if (ACC.config.isRegisterOrganisationVb4cEnabled) {
                ACC.newOrgAddress.validateOrganisationAddress();
            } else {
                ACC.newOrgAddress.checkExistingOrganizations(this);
            }
        });
    },

    associateToSuggestedCompany() {
        const existingOrgsContainer = $('.existing-org-popup-form-wrapper');
        const selectedOrg = existingOrgsContainer.find('.c-radiobtn.selected .c-radiobtn__input');
        const accountNumber = selectedOrg.data('account-number');
        const idParty = selectedOrg.data('idparty');
        const idAddress = selectedOrg.data('idaddress');
        const termsConditions = $('#termsConditionsExisting');
        const termsConditionsContainer = termsConditions.closest('.form-group');

        if (!termsConditions.is(':checked')) {
            const errorMessage = termsConditions.data('error-message');
            termsConditionsContainer.addClass('has-error');

            if (!termsConditionsContainer.find('.help-block').length) {
                termsConditionsContainer.find('.c-terms-conditions-area')
                    .append(`<div class="help-block"><span id="terms.errors">${errorMessage}</span></div>`);
            }

            return;
        }
        if (ACC.config.isRegisterOrganisationVb4cEnabled) {
            // eslint-disable-next-line max-len
            ACC.newOrgAddress.submitAssociateToSuggestedCompanyForm(idParty, idAddress, accountNumber);
        } else {
            ACC.newOrgAddress.submitAssociateToExistingForm(accountNumber);
        }
    },

    bindTermsConditionExistingOrg() {
        const termsConditions = $('#termsConditionsExisting');
        const termsConditionsContainer = termsConditions.closest('.form-group');

        termsConditions.on('click', () => {
            termsConditionsContainer.find('.help-block').remove();
            termsConditionsContainer.removeClass('has-error');
        });
    },

    submitAssociateToExistingForm(accountNumber) {
        const form = $('#registerExistingCompanyForm');

        if (accountNumber) {
            form.find('#number').val(accountNumber);
            document.getElementById('termsConditions').checked = true;
        }
        if (ACC.newOrgAddress.isValidAssociateToExistingForm()) {
            form.submit();
            ACC.spinner.start();
        }
    },

    // eslint-disable-next-line max-len
    submitAssociateToSuggestedCompanyForm(idParty, idAddress, accountNumber) {
        const form = $('#registerSuggestedCompanyForm');

        form.find('#number').val(accountNumber);
        form.find('#termsConditions').val(true);
        form.find('#idParty').val(idParty);
        form.find('#idAddress').val(idAddress);
        document.getElementById('termsConditions').checked = true;

        if (ACC.newOrgAddress.isValidAssociateToExistingForm()) {
            form.submit();
            ACC.spinner.start();
        }
    },

    isValidAssociateToExistingForm() {
        const form = $('#registerExistingCompanyForm');
        const validator = form.validate();
        // eslint-disable-next-line no-bitwise
        return validator.element(form.find('#number')) & validator.element(form.find('#termsConditions'));
    },

    isOrganizationAlreadySearched() {
        return $('.existing-org-popup-form-wrapper').length;
    },

    isUsingExistingOrganization() {
        return $('#existingOrg').closest('.c-radiobtn').hasClass('selected');
    },

    scrollTo(element) {
        const scrollOffset = 10;
        $('html, body').animate({
            scrollTop: $(element).offset().top - scrollOffset,
        }, 1000);
    },

    bindRegisterOrg() {
        if ($('#registerNewCompanyForm').length > 0) {
            ACC.registerPersona.populateCategoriesAndSubcategories();
            ACC.utils.setPlaceholderPostcodeValue();
            ACC.newOrgAddress.updatePostalCode();
            const registrationForm = document.querySelector('#registerNewCompanyForm');
            ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(registrationForm);
        }
    },

    bindResetForm() {
        $('.js-register-new-company-form__reset').on('click', () => {
            ACC.newOrgAddress.resetForm();
            $('#newOrg').click();
            $('#qGlobalButtonNo').click();
            ACC.newOrgAddress.scrollTo('#newOrg');
        });
    },

    bindRegisterNewOrgAnyway() {
        $('.js-register-new-org-anyway').on('click', () => {
            $('.js-register-new-company-form__address-data-toggle').show();
            $('.register-new-company-more-information-wrapper').removeClass('hidden');
            $('.js-register-new-company-form__check-existing').hide();
            $('.js-organization-listing').hide();
            $('.existing-org-popup-form-wrapper').remove();
            $('.js-register-new-org-anyway').hide();
        });
    },

    submitFormCallback() {
        ACC.newOrgAddress.toggleFormElements(false, '.register-new-company-form__address-data');
        ACC.newOrgAddress.toggleFormElements(false, '.register-new-company-form__org-country-zip');

        ACC.newOrgAddress.updateCountryIso();
    },

    bindEnterKeyOnSubmitForm() {
        $('#registerNewCompanyForm').on('keypress', (e) => {
            const enterKeyCode = 13;
            if (e.which === enterKeyCode) {
                e.preventDefault();
                if (!document.getElementById('registerNewCompanyMiniForm.companyName').disabled) {
                    ACC.newOrgAddress.checkExistingOrganizations();
                } else {
                    $('#registerNewCompanyForm').submit();
                }
            }
        });
    },

    checkExistingOrganizations(element) {
        let spinnerMessage = $(element).data('spinner-message');
        const timeout = $(element).data('request-timeout');

        if (ACC.newOrgAddress.isValidMiniForm()) {
            const organizationData = {
                name: $('#registerNewCompanyMiniForm\\.companyName').val(),
                country: $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector').val(),
                zipCode: $('#registerNewCompanyMiniForm\\.postcode').val(),
            };

            spinnerMessage = spinnerMessage.replace(ACC.newOrgAddress.ORG_NAME_PLACEHOLDER, organizationData.name);
            ACC.spinner.start(spinnerMessage);

            ACC.newOrgAddress.updatePostalCode();
            ACC.newOrgAddress.updateCountryIso();

            const context = ACC.newOrgAddress.getURLContext();

            $.ajax({
                type: 'POST',
                contentType: 'application/json',
                url: `${ACC.config.encodedContextPath}${context}/existing-organizations`,
                data: JSON.stringify(organizationData),
                error() {
                    ACC.newOrgAddress.checkExistingOrganizationsErrorHandler(timeout);
                },
                success(response) {
                    ACC.newOrgAddress.checkExistingOrganizationsSuccessHandler(response, timeout);
                },
            });
        }
    },

    isValidMiniForm() {
        if (ACC.config.isRegisterOrganisationVb4cEnabled) {
            // eslint-disable-next-line no-bitwise
            return ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.companyName')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.orgAddressCountrySelector')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.postcode')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.line1')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.line2')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.line3')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.townCity')
                & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.regionIso');
        }
        // eslint-disable-next-line no-bitwise
        return ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.companyName')
            & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.orgAddressCountrySelector')
            & ACC.newOrgAddress.isValidElement('#registerNewCompanyMiniForm\\.postcode');
    },

    isValidElement(element) {
        return $('#registerNewCompanyForm').validate().element(element);
    },

    checkExistingOrganizationsErrorHandler(timeout) {
        ACC.newOrgAddress.displayCountrySpecificAddressData();
        ACC.spinner.stop(timeout);
    },

    checkExistingOrganizationsSuccessHandler(data, timeout) {
        setTimeout(() => {
            ACC.newOrgAddress.displayOrganizations(data);
            ACC.newOrgAddress.displayCountrySpecificAddressData();
            const checkExistingButton = $('.js-register-new-company-form__check-existing');
            if (ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow()) {
                checkExistingButton.hide();
            } else {
                checkExistingButton.attr('disabled', 'disabled');
            }
            ACC.spinner.stop();
        }, timeout);
    },

    displayCountrySpecificAddressData() {
        const options = {
            countryIsoCode: $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector').val(),
        };

        $.ajax({
            url: `${ACC.config.encodedContextPath}/my-account/add-a-company/newOrgAddress`,
            data: options,
            dataType: 'html',
        }).done((data) => {
            if ($('.js-existing-org-radio-button').length) {
                const addressData = $('.js-register-new-company-form__address-data');
                addressData.show();
                addressData.removeAttr('checked');
            } else {
                ACC.newOrgAddress.showNewAddressForm();
                $('.js-register-new-company-form__reset').show();
            }

            $('#countryAddressData').empty();
            $('#countryAddressData').append($(data).html());

            ACC.newOrgAddress.updateForm();
            // eslint-disable-next-line max-len
            ACC.phonemasking.bind($('#address\\.phone'), options.countryIsoCode === 'UM' ? 'US' : options.countryIsoCode);
            if (ACC.config.countriesWithoutPostalCodeValidation.includes(options.countryIsoCode)) {
                $('#registerNewCompanyForm').validate().settings.rules['registerNewCompanyMiniForm.postcode'] = {
                    required: false,
                };
            } else if (ACC.config.countriesWithPostCodeValidation.includes(options.countryIsoCode)) {
                $('#registerNewCompanyForm').validate().settings.rules['pearsonProfileAddressForm.postcode'] = {
                    required: true,
                    minlength: 1,
                    maxlength: 30,
                    postCodeInvalid: true,
                };
            }
        });
    },

    resetExistingOrgForm() {
        const validator = $('#registerExistingCompanyForm').validate();
        validator.resetForm();
    },

    resetForm() {
        $('#actAsAdministrator').removeAttr('checked');
        $('#termsConditionsNew').removeAttr('checked');

        const validator = $('#registerNewCompanyForm').validate();
        validator.resetForm();

        ACC.newOrgAddress.resetChooseNewOrganisationForm();

        ACC.newOrgAddress.toggleFormElements(false, '.register-new-company-form__org-country-zip');
        $('.register-new-company-form__address-data').addClass('hidden');
        $('.register-new-company-more-information-wrapper').addClass('hidden');

        $('.js-register-new-company-form__address-data-toggle').hide();
        $('.js-organization-listing').hide();
        $('.existing-org-popup-form-wrapper').remove();
        $('.existing-org-found-button').hide();
        $('.js-register-new-company-form__reset').hide();
    },

    updateForm() {
        ACC.newOrgAddress.toggleFormElements(true, '.register-new-company-form__org-country-zip');
        ACC.newOrgAddress.toggleFormElements(true, '#register-new-company-choose-form');
        $('.register-new-company-form__address-data').removeClass('hidden');
        ACC.newOrgAddress.updatePostalCode();
    },

    displayOrganizations(data) {
        const organizationList = $('.js-organization-listing');

        if ($(data).find('.js-existing-org-radio-button').length) {
            // eslint-disable-next-line no-undef
            organizationList.html(DOMPurify.sanitize(data));
            organizationList.show();
        }
    },

    updatePostalCode() {
        const pattern = $('#zipValidationPattern').val();
        const postCode = $('#registerNewCompanyMiniForm\\.postcode').val();
        const postCodeInput = $('#address\\.postcode');
        if (!postCodeInput.val()) {
            postCodeInput.val(postCode);
        }
        if (new RegExp(pattern).test(postCode)) {
            postCodeInput.prop('readonly', true);
        } else {
            postCodeInput.prop('readonly', false);
        }
    },

    updateCountryIso() {
        const $countryIso = $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector');

        if ($countryIso.length) {
            $('#address\\.countryIso').val($countryIso.val());
        }
    },

    toggleFormElements(toggle, form) {
        ACC.newOrgAddress.setDisabled(toggle, $('input', form));
        ACC.newOrgAddress.setDisabled(toggle, $('select', form));
        ACC.newOrgAddress.setDisabled(toggle, $('button', form));
    },

    setDisabled(isDisabled, elements) {
        elements.each(function () {
            $(this).prop('disabled', isDisabled);
        });
    },

    bindRadioButtonSelection() {
        const radioButtons = $('.js-register-org-radio-button');
        const addNewAddressFormCard = $('.js-register-new-company-form__address-data');
        const organizationList = $('.js-organization-listing');

        let previousRadioButtonSelected = $('input[type=radio][name=orgType]').filter(':checked').val();

        radioButtons.on('click', function (e) {
            ACC.utils.setPlaceholderPostcodeValue();

            if ($('.js-skip-qglobal-search').hasClass('selected')) {
                $('.register-new-company-form__org-country-zip').removeClass('hidden');
                ACC.newOrgAddress.selectSkipQglobalSearchButton();
            }

            const target = $(e.target);
            const targetId = target.attr('id');

            if (previousRadioButtonSelected === targetId) {
                return;
            }
            previousRadioButtonSelected = targetId;

            radioButtons.closest('.c-radiobtn').removeClass('selected');
            radioButtons.attr('checked', false);

            addNewAddressFormCard.hide();
            if (ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow()) {
                ACC.newOrgAddress.hideCollapsedCardButton(targetId);
                const continueButton = $('.js-register-new-company-form__check-existing');
                continueButton.text(continueButton.data('add-selected-organization-text'));
            } else {
                ACC.newOrgAddress.hideNewAddressForm();
            }
            organizationList.hide();
            $('#termsConditionsExisting').closest('.form-group').hide();
            $(this).closest('.c-radiobtn').addClass('selected');
            target.attr('checked', true);
            $('.js-register-new-org-anyway').hide();

            // eslint-disable-next-line default-case
            switch (targetId) {
            case 'newOrg':
                ACC.newOrgAddress.selectNewOrgHandler();
                break;
            case 'existingOrg':
                ACC.newOrgAddress.selectExistingOrgHandler();
                break;
            case 'skipOrg':
                ACC.newOrgAddress.selectSkipOrgHandler();
                break;
            }
        });
    },

    selectNewOrgHandler() {
        // const continueButton = $('.js-register-new-company-form__check-existing');
        const skipOrg = document.getElementById('skipOrg');
        const countrySelector = $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector');
        const regionField = $('#registerNewCompanyMiniForm\\.regionIso');
        document.getElementById('newOrg').checked = true;
        document.getElementById('existingOrg').checked = false;

        // eslint-disable-next-line max-len
        if (ACC.config.isRegisterOrganisationVb4cEnabled && countrySelector.length === 1 && countrySelector.hasClass('read-only')) {
            ACC.newOrgAddress.loadCountryRegions(countrySelector.val(), regionField);
        }
        if (countrySelector.hasClass('read-only') || countrySelector.val() === null) {
            ACC.utils.setPlaceholderPostcodeValue();
        }

        if (skipOrg) {
            skipOrg.checked = false;
        }

        ACC.newOrgAddress.resetExistingOrgForm();
        ACC.newOrgAddress.showContinueButton();
        ACC.newOrgAddress.scrollTo('#newOrg');
    },

    loadCountryRegions(countryIso, regionField) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/address/country/${countryIso}/regions`,
            async: true,
        }).done((regions) => {
            if (regions.length > 0 && countryIso !== 'GB' && countryIso !== 'CN' && countryIso !== 'NZ') {
                regionField.find('option:not(:first-child)').remove();
                $.each(regions, function () {
                    $('<option>', { value: this.isocodeShort, text: this.name }).appendTo(regionField);
                });
                regionField.find('option:first').prop('selected', 'selected');
                $('#registerNewCompanyRegion').removeClass('hidden');
            } else {
                $('#registerNewCompanyRegion').addClass('hidden');
            }
        });
    },

    selectExistingOrgHandler() {
        const continueButton = $('.js-register-new-company-form__check-existing');

        ACC.newOrgAddress.resetForm();
        if (!(ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow())) {
            continueButton.show();
            continueButton.removeAttr('disabled');
        }
    },

    selectSkipOrgHandler() {
        ACC.newOrgAddress.resetForm();
        ACC.newOrgAddress.resetExistingOrgForm();
        $('.js-register-new-company-form__check-existing').hide();
    },

    bindExistingOrgRadioButton() {
        $('#registerNewCompanyForm').on('click', '.js-existing-org-radio-button', (event) => {
            ACC.newOrgAddress.selectOrganization(event.target);
            ACC.newOrgAddress.hideNewAddressForm();
        });
    },

    selectOrganization(element) {
        const existingOrganizationButtonsSelector = '.js-existing-org-radio-button';
        const continueButton = $('.js-register-new-company-form__check-existing');
        const registerAnywayButton = $('.js-register-new-org-anyway');

        $(existingOrganizationButtonsSelector).removeClass('selected');
        $(element).closest(existingOrganizationButtonsSelector).addClass('selected');

        continueButton.show();
        continueButton.removeAttr('disabled');
        if (ACC.config.isRegisterOrganisationVb4cEnabled) {
            continueButton.text(continueButton.data('add-selected-organization-text'));
            registerAnywayButton.text(registerAnywayButton.data('register-organization-anyway-text'));
        }
        if (ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow()) {
            const continueWithExisting = $('.existing-org-found-button');
            continueWithExisting.text(continueWithExisting.data('continue-with-existing-org-text'));
            continueWithExisting.show();
            $('.new-org-button').hide();
        }

        $('#termsConditionsExisting').closest('.form-group').show();
    },

    bindAddNewAddressRadioForm() {
        $('.js-register-new-company-form__address-data-radio-button').on('click', () => {
            $('.js-existing-org-radio-button').removeClass('selected');
            $('.js-register-new-company-form__check-existing').hide();
            $('#termsConditionsExisting').closest('.form-group').hide();
            ACC.newOrgAddress.showNewAddressForm();
            $('.js-register-new-company-form__reset').show();
        });
    },

    hideNewAddressForm() {
        $('.js-register-new-company-form__address-data-toggle').hide();
        $('.js-register-new-company-form__address-data-radio-button').removeClass('selected');
        $('.register-new-company-more-information-wrapper').addClass('hidden');
        // $('.js-register-new-company-form__button').addClass('hidden');
        ACC.newOrgAddress.showContinueButton();
    },

    hideCollapsedCardButton(targetId) {
        $('.js-register-new-company-form__address-data-toggle').hide();
        $('.js-register-new-company-form__address-data-radio-button').removeClass('selected');
        if (targetId === 'existingOrg') {
            $('.existing-org-button').show();
            $('.new-org-button').hide();
        } else {
            $('.existing-org-button').hide();
            $('.new-org-button').show();
        }
        $('.existing-org-found-button').hide();
    },

    showNewAddressForm() {
        $('.js-register-new-company-form__address-data-toggle').show();
        $('.js-register-new-company-form__address-data-radio-button').addClass('selected');
        $('.js-register-new-company-form__check-existing').hide();
    },

    showOrgErrorPopup() {
        const orgErrorPopup = $('.js-org-error-popup').data('org-error-popup');
        if (orgErrorPopup === true) {
            $('#accountRegisterOrgPageErrorTrigger').click();
        }
    },

    bindToggleContinueButton() {
        const continueButton = $('.js-register-new-company-form__check-existing');
        const existingOrgRadio = $('input#existingOrg');
        const newOrgRadio = $('input#newOrg');
        const newOrgFormRadio = $('input#newOrgForm');
        if (existingOrgRadio.is(':checked') || newOrgRadio.is(':checked')) {
            continueButton.show();
            continueButton.removeAttr('disabled');
        }
        if (newOrgRadio.is(':checked') && newOrgFormRadio.is(':checked')) {
            continueButton.hide();
        }
        if (newOrgRadio.is(':checked') && $('.js-existing-org-radio-button').length > 0) {
            continueButton.attr('disabled', 'disabled');
        }
        if (ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow()) {
            if (existingOrgRadio.is(':checked')) {
                $('.existing-org-button').show();
            }
            if (newOrgRadio.is(':checked')) {
                $('.new-org-button').show();
            }
            $('.existing-org-found-button').hide();
        }
    },

    isPractitionerSignin() {
        return ACC.config.practitionerSignin === 'true';
    },

    isRegistrationFlow() {
        return $('#isRegistrationFlow').val() === 'true';
    },

    bindQGlobalSearchForm() {
        ACC.newOrgAddress.bindUseQGlobalSearchFormButton();
        ACC.newOrgAddress.bindSkipQGlobalSearchFormButton();
        ACC.newOrgAddress.bindSubmitQGlobalSearchFormButton();
    },

    bindUseQGlobalSearchFormButton() {
        $('.js-use-qglobal-search').click(() => {
            $('.register-new-company-form__org-country-zip').addClass('hidden');
            $('.js-register-new-company-form__check-existing').hide();

            $('.js-use-qglobal-search').addClass('selected');
            $('.js-use-qglobal-search').attr('aria-selected', 'true');
            $('.js-skip-qglobal-search').removeClass('selected');
            $('.js-skip-qglobal-search').attr('aria-selected', 'false');

            ACC.newOrgAddress.resetQGlobalSearchForm();
            $('#registration-q-global-search-form').removeClass('hidden');
        });
    },

    bindSkipQGlobalSearchFormButton() {
        $('.js-skip-qglobal-search').click(() => {
            $('.register-new-company-form__org-country-zip').removeClass('hidden');
            $('#registration-q-global-search-form').addClass('hidden');
            ACC.newOrgAddress.showContinueButton();
            $('.js-register-new-company-form__check-existing').removeAttr('disabled');
            ACC.newOrgAddress.selectSkipQglobalSearchButton();
            if (!ACC.config.isRegisterOrganisationVb4cEnabled) {
                $('.new-org-button').show();
            }
        });
    },

    selectSkipQglobalSearchButton() {
        $('.js-skip-qglobal-search').addClass('selected');
        $('.js-skip-qglobal-search').attr('aria-selected', 'true');
        $('.js-use-qglobal-search').removeClass('selected');
        $('.js-use-qglobal-search').attr('aria-selected', 'false');
    },

    bindSubmitQGlobalSearchFormButton() {
        const submitQGlobalSearchButton = $('#registration-q-global-search-form-submit-button');
        submitQGlobalSearchButton.click((e) => {
            e.preventDefault();
            if (ACC.validation.validateFormDiv('#registration-q-global-search-form')) {
                const context = ACC.newOrgAddress.getURLContext();
                const accountNumber = $('#qGlobalAccountNumber').val();
                // eslint-disable-next-line max-len
                const url = `${ACC.config.encodedContextPath}${context}/get-organisation-for-account?accountNumber=${accountNumber}`;
                ACC.buttonhelper.disable(submitQGlobalSearchButton).withSpinner();
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url,
                    success(response) {
                        ACC.buttonhelper.enable(submitQGlobalSearchButton);
                        if (response.qGlobalAccountFoundModalTitle !== undefined) {
                            ACC.newOrgAddress.displayModalForFoundOrganisation(response);
                        } else if (response.qGlobalAccountNotFoundModalTitle !== undefined) {
                            ACC.newOrgAddress.displayModalForWrongQGlobalAccountNumber();
                        } else {
                            ACC.digitalAccounts.checkOrgPartyHandler(response);
                        }
                    },
                });
            }
        });
    },

    displayModalForFoundOrganisation(data) {
        $modal.open(null, {
            html: data.qGlobalAccountFoundModalContent,
            title: data.qGlobalAccountFoundModalTitle,
        });
        $('#colorbox').on('click', '.js-search-q-global-cancel-button', $modal.close);
    },

    displayModalForWrongQGlobalAccountNumber() {
        ACC.digitalAccountsViewHelper.showElement('#noQglobalAccountFoundPopupPageId');
        $('#noQglobalAccountFoundPopup').click();
    },

    resetQGlobalSearchForm() {
        $('#qGlobalAccountNumber').val('');
        const formId = 'registration-q-global-search-form';
        $(`#${formId} .form-group`).removeClass('has-error');
        $(`#${formId} .help-block`).remove();
    },

    resetChooseNewOrganisationForm() {
        const continueButton = $('.js-register-new-company-form__check-existing');
        ACC.newOrgAddress.toggleFormElements(false, '#register-new-company-choose-form');

        $('.js-use-qglobal-search, .js-skip-qglobal-search').removeClass('selected');
        $('.js-use-qglobal-search, .js-skip-qglobal-search').attr('aria-selected', 'false');
        $('#registration-q-global-search-form').addClass('hidden');

        ACC.newOrgAddress.resetQGlobalSearchForm();

        $('#registerNewCompanyMiniForm\\.postcode').attr('readonly', false);
        $('.register-new-company-form__org-country-zip').addClass('hidden');
        ACC.newOrgAddress.resetNewCompanyMiniFormFields();
        continueButton.text(continueButton.data('continue-text'));
        if (!(ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow())) {
            $('.js-register-new-company-form__check-existing').hide();
        }
    },

    getURLContext() {
        return ACC.newOrgAddress.isRegistrationFlow() ? '/register/organisation' : '/my-account/add-a-company';
    },

    validateOrganisationAddress() {
        if (ACC.newOrgAddress.isValidMiniForm()) {
            const formData = $('#registerNewCompanyForm').serialize();
            const context = ACC.newOrgAddress.getURLContext();
            $.ajax({
                type: 'POST',
                url: `${ACC.config.encodedContextPath}${context}/validateAddress`,
                data: formData,
                success(response) {
                    $('#suggestedAddressesOrgForm').html(response);
                    if ($('#showAddressDoctorModal').val() === 'true') {
                        ACC.addressDoctor.showSuggestedAddressesPopup();
                    } else if ($('#formHasErrors').val() === 'true') {
                        ACC.newOrgAddress.processErrors();
                    } else {
                        $('#registerOrgMiniForm').submit();
                    }
                },
            });
        }
    },

    processErrors() {
        const errors = document.getElementsByClassName('orgErrorField');
        for (let i = 0; i < errors.length; i += 1) {
            const error = errors[i];
            const errorMessage = error.textContent;
            const fieldName = 'registerNewCompanyMiniForm.'.concat(error.getAttribute('value'));
            const field = document.getElementById(fieldName.toString());
            const container = field.closest('.form-group');
            $(container).addClass('has-error');
            $(container).append(`<div class="help-block"><span class="has-error">${errorMessage}</span></div>`);
        }
    },

    displayAddressLine3() {
        const selectedCountry = $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector').val();
        if (!ACC.config.countriesWithAddressLine3.includes(selectedCountry)) {
            $('#registerNewCompanyLine3').addClass('hidden');
        } else {
            $('#registerNewCompanyLine3').removeClass('hidden');
        }
    },

    bindCountrySelector() {
        const countrySelector = $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector');
        const regionField = $('#registerNewCompanyMiniForm\\.regionIso');
        countrySelector.on('input', () => {
            const selectedCountry = countrySelector.val();
            // eslint-disable-next-line max-len
            if (ACC.config.isHidePostalCodeFieldByCountryListEnabled && !ACC.config.countriesMissingPostalCode.indexOf(selectedCountry) >= 0) {
                document.getElementById('registerNewCompanyMiniForm.postcode').style.display = 'block';
                $('[for = "registerNewCompanyMiniForm.postcode"]').css('display', 'block');
            } ACC.utils.setPlaceholderPostcodeValue();
            if (ACC.config.isRegisterOrganisationVb4cEnabled) {
                ACC.newOrgAddress.displayAddressLine3();
                ACC.newOrgAddress.loadCountryRegions(selectedCountry, regionField);
                $('input[id^="registerNewCompanyMiniForm\\.townCity"]').val('');
                $('input[id^="registerNewCompanyMiniForm\\.line1"]').val('');
                $('input[id^="registerNewCompanyMiniForm\\.line2"]').val('');
                $('input[id^="registerNewCompanyMiniForm\\.line3"]').val('');
                $('input[id^="registerNewCompanyMiniForm\\.postcode"]').val('');
                $('.registerNewCompanyMiniFormVb4c .form-group').removeClass('has-error');
                $('.registerNewCompanyMiniFormVb4c .help-block').remove();
            }
        });
    },

    bindQglobalAccountSearchButtons() {
        if ($('#qGlobalButtonNo').hasClass('selected')) {
            $('.register-new-company-form__org-country-zip').removeClass('hidden');
        } else {
            $('.register-new-company-form__org-country-zip').addClass('hidden');
            $('.register-new-company-more-information-wrapper').addClass('hidden');
            $('.js-register-new-company-form__address-data-toggle').hide();
        }
    },

    showContinueButton() {
        if (ACC.config.isRegisterOrganisationVb4cEnabled) {
            ACC.newOrgAddress.showContinueButtonBasedOnForm();
        // eslint-disable-next-line max-len
        } else if (!(ACC.newOrgAddress.isPractitionerSignin() && ACC.newOrgAddress.isRegistrationFlow())) {
            ACC.newOrgAddress.showContinueButtonBasedOnForm();
        }
    },

    showContinueButtonBasedOnForm() {
        if (!$('.register-new-company-form__org-country-zip').hasClass('hidden')) {
            $('.js-register-new-company-form__check-existing').show();
        } else {
            $('.js-register-new-company-form__check-existing').hide();
        }
    },

    resetNewCompanyMiniFormFields() {
        if (ACC.config.isRegisterOrganisationVb4cEnabled) {
            $('input[id^="registerNewCompanyMiniForm\\."]').val('');
            $('select[id^="registerNewCompanyMiniForm\\."]').not('.read-only').filter(function () {
                return !this.id.match(/country/);
            }).val('');
            $('#registerNewCompanyMiniForm\\.addressValid').val(false);
            if (!$('#registerNewCompanyMiniForm\\.orgAddressCountrySelector').hasClass('read-only')) {
                $('#registerNewCompanyMiniForm\\.regionIso').find('option:not(:first-child)').remove();
            }
        }
    },

    bindPreselectExistingOrg() {
        const existingOrganizationButtonsSelector = $('.js-existing-org-radio-button');
        if (existingOrganizationButtonsSelector.length === 1) {
            $('.js-existing-org-radio-button').click();
        }
    },

    bindShowMoreOrganizationsButton() {
        $('#js-show-more-existing-org').on('click', () => {
            $('.js-existing-org-radio-button:hidden').each((index, element) => {
                if (index < parseInt(ACC.config.existingOrgPaginationValue, 10)) {
                    $(element).removeClass('hidden');
                }
            });
            if ($('.js-existing-org-radio-button:hidden').length === 0) {
                $('#js-show-more-existing-org').addClass('hidden');
            }
        });
    },
};
