ACC.login = {

    _autoload: [
        'bindSubmitLink',
        'bindRegistrationSubmitLink',
        'bindGuestRegistrationSubmitLink',
        'bindResetPasswordLink',
        'bindLoginRefererAction',
        'addBackground',
        'showHidePassword',
        'bindLeaveRegisterModalPopup',
        'bindRegistrationCountrySelector',
        'bindRegistrationStepConfirmButton',
        'bindSpecialCharactersCheck',
    ],

    defaultRedirectLink: '/',
    secondRegistrationStep: 2,
    decimalNumberRadix: 10,

    signIn(username, password, rememberMe, originalReferer, successMessage, orderGUID) {
        if (ACC.config.isSsoEnabled === 'true') {
            ACC.login.signInWithSso(username, password, rememberMe, originalReferer, successMessage, orderGUID);
        } else if (ACC.config.forgerockSsoEnabled) {
            ACC.forgerock.login(username, password, rememberMe, originalReferer);
        } else {
            ACC.login.signInWithoutSso(username, password, rememberMe, originalReferer, successMessage, orderGUID);
        }
    },

    bindLoginRefererAction() {
        if (!piSession && !window.location.href.includes('/login')) {
            // eslint-disable-next-line no-undef
            $.cookie('siginInReferer', encodeURI(DOMPurify.sanitize(window.location.href)), { path: '/' });
        }
    },

    signInWithSso(username, password, rememberMe, originalReferer, successMessage, orderGUID) {
        function onSuccess(jqXHR) {
            $.ajax({
                async: false,
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/login/setToken`,
                data: { username, password },
            });
            if (orderGUID) {
                $.ajax({
                    type: 'POST',
                    url: `${ACC.config.encodedContextPath}/login/order/link`,
                    data: { userId: jqXHR.data.userId, orderGUID },
                });
            }
            if (ACC.config.upcPreferencesEnabled) {
                $.ajax({
                    async: false,
                    type: 'POST',
                    url: `${ACC.config.encodedContextPath}/login/setIesUserToken`,
                    data: { username, password },
                });
            }
            if (jqXHR.mock && piSession.Mock) {
                console.warn('Mock piSession usage');
                piSession.mockInitialize(jqXHR.data);
            }
            $.ajax({
                async: false,
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/login/createPearsonCustomer`,
                data: { username },
            });
            $.ajax({
                async: false,
                type: 'POST',
                url: `${ACC.config.encodedContextPath}/login/setRefererInSession`,
                data: { originalReferer },
            });
        }

        function onError(jqXHR) {
            let messageKey;
            if (jqXHR.status === 401) {
                messageKey = `account.error.${jqXHR.responseJSON.fault.detail.errorcode}`;
            } else if (jqXHR.status === 403) {
                const url = `${ACC.config.encodedContextPath}/login/acceptconsents`;

                $('#escrowTicket').val(jqXHR.responseJSON.message);
                $('#pearsonLoginForm').attr('action', url);
                $('#pearsonLoginForm').off('submit');

                setTimeout($('#pearsonLoginForm')[0].submit(), 100);
            } else {
                messageKey = 'account.error.generic';
            }
            if (messageKey) {
                const globalMessageRequest = {
                    globalMessages: [{
                        messageHolder: 'accErrorMsgs',
                        messages: [messageKey],
                    }],
                };
                ACC.globalmessages.createGlobalMessage(globalMessageRequest);
            }
        }

        ACC.iamActions.triggerIamLoginFromEmbeddedPage(
            username,
            password,
            rememberMe,
            originalReferer,
            successMessage,
            onSuccess,
            onError,
        );
    },

    signInWithoutSso(username, password, rememberMe, originalReferer, successMessage, orderGUID) {
        const urlParams = new URLSearchParams(window.location.search);
        const referer = urlParams.has('referer') ? ACC.login.defaultRedirectLink : originalReferer;
        $.cookie('siginInReferer', null, { path: '/' });

        orderGUID = orderGUID || '';

        ACC.forms.createAndSubmitForm(
            `${ACC.config.encodedContextPath}/login/signin`,
            {
                username,
                password,
                rememberMe,
                loginReferer: referer,
                successMessage,
                orderGUID,
                formURL: window.location.href,
                CSRFToken: ACC.config.CSRFToken,
            },
        );
    },

    bindSubmitLink() {
        $(document).on('submit', '#pearsonLoginForm', (e) => {
            e.preventDefault();

            ACC.globalmessages.removeGlobalMessages();

            const referer = $('#loginReferer').val() || ACC.login.defaultRedirectLink;

            ACC.login.signIn(
                $('#username').val(),
                $('#password').val(),
                $('#rememberMeLogin').is(':checked'),
                referer,
                '',
                $('#orderGUID').val(),
            );
        });
    },

    handleRegistration(url, formData, uid, password, rememberMe, referer, successMessage) {
        let originalReferer = '/register';
        if (ACC.config.practitionerSignin === 'true') {
            originalReferer = '/register/personal-info';
        }
        $.ajax({
            type: 'POST',
            url,
            data: formData,
            beforeSend() {
                ACC.spinner.start();
            },
            success() {
                ACC.spinner.stop();
                $('#pearsonRegistrationForm :input').prop('disabled', true);
                $('#pearsonRegistrationForm :button').prop('disabled', true);
                ACC.login.signIn(uid, password, rememberMe, originalReferer, successMessage);
            },
            error(jqXHR) {
                console.log(jqXHR);
                ACC.spinner.stop();
                if ($('[data-recaptcha-control="getRegisterInvisibleRecaptcha"]').length) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.reset();
                }

                if ($('#practitionerError').length) {
                    $('#practitionerError').removeClass('hidden');
                    // eslint-disable-next-line no-undef
                    $('#practitionerErrorMsg').html(DOMPurify.sanitize(jqXHR.responseJSON.ERROR_MESSAGE));
                    $('html, body').animate({ scrollTop: 0 }, 'fast');
                    if (jqXHR.responseJSON.ERROR_CODE === 'register.user.exists.error') {
                        $('#userID').parent('div').addClass('has-error');
                    }
                    if (jqXHR.responseJSON.ERROR_CODE === 'register.email.exists.error') {
                        $('#emailID').parent('div').addClass('has-error');
                    }
                } else {
                    const globalMessageRequest = {
                        globalMessages: [{
                            messageHolder: 'accErrorMsgs',
                            messages: [jqXHR.responseJSON],
                        }],
                    };
                    ACC.globalmessages.createGlobalMessage(globalMessageRequest);
                }
            },
        });
    },

    bindGuestRegistrationSubmitLink() {
        $(document).on('submit', '#pearsonGuestRegisterForm', function (e) {
            e.preventDefault(); // avoid to execute the actual submit of the form.

            ACC.globalmessages.removeGlobalMessages();

            const form = $(this);
            const url = form.attr('action');

            ACC.login.handleRegistration(
                url,
                form.serialize(),
                $('#uid').val(),
                $('#password').val(),
                $('#rememberMe').is(':checked'),
                ACC.config.oneDnsForMultipleStores === 'true' ? $('#defaultAddressCountrySelector').val() : '',
                $('#referer').val(),
            );
        });
    },

    bindRegistrationSubmitLink() {
        $(document).on('submit', '#pearsonRegistrationForm', (e) => {
            e.preventDefault(); // avoid to execute the actual submit of the form.

            if ($('[data-recaptcha-control="getRegisterInvisibleRecaptcha"]').length) {
                // eslint-disable-next-line no-undef
                grecaptcha.execute(); // invoke the reCAPTCHA check.
            } else {
                ACC.login.callHandleRegistration();
            }
        });
    },

    bindResetPasswordLink() {
        $(document).on('submit', '#forgottenPwdForm', function (e) {
            e.preventDefault();

            ACC.buttonhelper.disable($(this).find('button'));

            this.submit();
        });
    },

    callHandleRegistration() {
        const form = $('#pearsonRegistrationForm');

        ACC.globalmessages.removeGlobalMessages();

        const url = form.attr('action');

        ACC.login.handleRegistration(
            url,
            form.serialize(),
            $('#userID').val(),
            $('#passwordID').val(),
            $('#rememberMeRegistration').is(':checked'),
            $('#referer').val(),
            '',
            $(),
        );
    },

    addBackground() {
        const element = document.querySelector('.loginRegistration');
        if (element) {
            element.parentElement.parentElement.style.background = '#FAFAFA';
        }
    },

    showHidePassword() {
        const element = document.querySelector('.loginRegistration');
        if (element) {
            const buttons = element.querySelectorAll('.loginRegistration__card--password');
            [].forEach.call(buttons, (button) => {
                button.addEventListener('click', (showHide) => {
                    showHide.target.classList.toggle('on');
                    const isShow = showHide.target.classList.contains('on');
                    showHide.target.parentElement.querySelector('.input')
                        .setAttribute('type', isShow ? 'text' : 'password');
                });
            });
        }
    },

    bindLeaveRegisterModalPopup() {
        if (ACC.login.showLeaveRegistrationPopup()) {
            $('header a, footer a, #custom-header').click((event) => {
                ACC.login.handleLeaveRegisterOnClick(event);
            });
            const initialLanguageSelected = $('.locale-selection>div>select :selected').val();
            $('.locale-selection>div>select').change((event) => {
                ACC.login.handleLeaveRegisterOnChange(event, initialLanguageSelected);
            });
            // eslint-disable-next-line max-len
            const currentRegistrationStep = parseInt($('#stepNumberOnRegistrationFlow').val(), ACC.login.decimalNumberRadix);
            const previousRegistrationStep = sessionStorage.getItem('previousRegistrationStep');
            ACC.login.handleStoreResetForMultiStoreSingleDNS(currentRegistrationStep, previousRegistrationStep);
        }
    },

    handleStoreResetForMultiStoreSingleDNS(currentRegistrationStep, previousRegistrationStep) {
        if (ACC.config.oneDnsForMultipleStores === 'true'
            && currentRegistrationStep === ACC.login.secondRegistrationStep
            && previousRegistrationStep > currentRegistrationStep) {
            sessionStorage.setItem('previousRegistrationStep', currentRegistrationStep);
            ACC.login.removeBusinessCountryFromSession(window.location.href);
        }
    },

    handleLeaveRegisterOnClick(event) {
        const target = $(event.target);
        if (target.parents('#mainPageContent').length === 0) {
            const linkElement = ACC.login.getLinkElement(target);
            if (ACC.login.isValidLink(linkElement)) {
                event.preventDefault();
                const leaveRegistrationHref = linkElement.attr('href');
                ACC.login.triggerLeaveRegistrationModal(leaveRegistrationHref);
            }
        }
    },

    handleLeaveRegisterOnChange(event, initialLanguageSelected) {
        event.preventDefault();
        const target = $(event.target);
        const leaveRegistrationHref = target.selected().val();
        $('.locale-selection>div>select').val(initialLanguageSelected);
        ACC.login.triggerLeaveRegistrationModal(leaveRegistrationHref);
    },

    triggerLeaveRegistrationModal(leaveRegistrationHref) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/register/personal-info/leave-registration`,
            async: true,
            type: 'GET',
            data: { leaveRegistrationHref },
            dataType: 'json',
            success(data) {
                ACC.login.displayLeaveRegistrationModal(data);
                ACC.login.bindLeaveRegistrationModalButtons();
            },
            error(xhr, status, error) {
                console.error(error.message);
            },
        });
    },

    isValidLink(el) {
        // eslint-disable-next-line max-len
        return el.attr('href') !== undefined && !el.hasClass('new-window') && !el.attr('href').startsWith('#') && !el.hasClass('js-return-to-edit-button');
    },

    getLinkElement(el) {
        return el.is('a') ? el : el.closest('a');
    },

    displayLeaveRegistrationModal(data) {
        $('#cboxOverlay').css({ 'background-color': 'rgba(0, 0, 0, 0.8)' });
        $modal.open(null, {
            html: data.leaveRegistrationModalContent,
            title: data.leaveRegistrationModalTitle,
            keyboardInteraction: false,
            closeButton: false,
            overlayClose: false,
        });
    },

    bindLeaveRegistrationModalButtons() {
        $('#colorbox').on('click', '.js-leave-registration-close-button', $modal.close);
        $('.js-return-to-edit-button').click((e) => {
            ACC.login.removeRegistrationContextFromSession();
            const redirectUrl = $('.js-return-to-edit-button').prop('href');
            ACC.login.removeBusinessCountryFromSession(redirectUrl);
            if (ACC.config.isSsoEnabled === 'true') {
                ACC.iamActions.logout(false);
            }
            if (ACC.config.forgerockSsoEnabled) {
                e.preventDefault();
                if (window.forgerockLogout) {
                    window.forgerockLogout()
                        .then(() => {
                            ACC.forgerock.hybrisLogout()
                                .then(() => {
                                    window.location.href = redirectUrl;
                                });
                        });
                } else {
                    window.location.href = redirectUrl;
                }
            }
            return false;
        });
    },

    removeRegistrationContextFromSession() {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/register/remove-registration-context-from-session`,
            async: true,
            type: 'DELETE',
            error(xhr, status, error) {
                console.error(error.message);
            },
        });
    },

    removeBusinessCountryFromSession(link) {
        $.ajax({
            url: `${ACC.config.encodedContextPath}/register/contact-address/remove-business-country-from-session`,
            async: true,
            type: 'DELETE',
            success() {
                if (ACC.config.isSsoEnabled !== 'true' && ACC.config.forgerockSsoEnabled !== true) {
                    // eslint-disable-next-line no-undef
                    window.location = DOMPurify.sanitize(link);
                }
            },
            error(xhr, status, error) {
                console.error(error.message);
            },
        });
    },

    showLeaveRegistrationPopup() {
        return $('#showLeaveRegistrationPopup').val() === 'true';
    },

    bindRegistrationCountrySelector() {
        if (ACC.config.registrationCountrySelectorDropdown === 'true') {
            $('#defaultRegisterCountrySelector').change((e) => {
                ACC.validation.triggerManualValidation('#registerCountrySelectorForm');
                e.target.classList.add('selectedCountry');
            });
        } else {
            ACC.login.bindRegistrationCountryButton();
        }
    },

    bindRegistrationCountryButton() {
        $('.js-registration-country-button').click((e) => {
            e.preventDefault();
            $('.loginRegistration__card--button').removeClass('selected');
            e.target.classList.add('selected');
            $('#registrationCountryIsoCode').val($(e.target).data('country-iso'));
            ACC.validation.triggerManualValidation('#registerCountrySelectorForm');
        });
    },

    bindRegistrationStepConfirmButton() {
        const confirmRegistrationStepBtn = document.querySelector('.confirmRegistrationStepBtn');
        if (confirmRegistrationStepBtn !== undefined && confirmRegistrationStepBtn !== null) {
            // eslint-disable-next-line max-len
            const currentRegistrationStep = parseInt($('#stepNumberOnRegistrationFlow').val(), ACC.login.decimalNumberRadix);
            sessionStorage.setItem('previousRegistrationStep', `${currentRegistrationStep}`);
        }
    },

    bindSpecialCharactersCheck() {
        const firstName = document.querySelector('#firstName');
        const lastName = document.querySelector('#lastName');
        const registrationPersonaForm = document.querySelector('#registerPersonaForm');
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(firstName);
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(lastName);
        ACC.replaceSpecialCharacters.bindSpecialCharactersConversion(registrationPersonaForm);
    },
};

// eslint-disable-next-line no-unused-vars
const recaptchBindRegistrationSubmitCallback = function () {
    ACC.login.callHandleRegistration();
};
